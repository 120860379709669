:root {
  --mg-color-dark: #000000;
  --mg-color-light: #fcfcfc;
  --mg-color-initial: #ffffff;
  --mg-color-primary: #9b4dca;
  --mg-color-secondary: #302f2f;
  --mg-color-tertiary: #797979;
  --mg-color-quaternary: #a7a7a7;
  --mg-color-quinary: #dadada;
  --mg-control-radius: 0.4rem;
  --mg-input-radius: 0.1rem;
  --mg-color-red: #dc3545;
  --mg-color-orange: #fd7e14;
  --mg-color-blue: #007bff;
  --mg-color-green: #28a745;
  --mg-control-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.15); }

.mg-theme-inverted {
  --mg-color-initial: #302f2f !important;
  --mg-color-secondary: #dadada !important;
  --mg-color-tertiary: #a7a7a7 !important;
  --mg-color-quaternary: #817f7f !important;
  --mg-color-quinary: #4e4e4e !important; }

.mg-color-initial {
  color: var(--mg-color-initial); }

.mg-color-primary {
  color: var(--mg-color-primary); }

.mg-color-secondary {
  color: var(--mg-color-secondary); }

.mg-color-tertiary {
  color: var(--mg-color-tertiary); }

.mg-color-quaternary {
  color: var(--mg-color-quaternary); }

.mg-color-quinary {
  color: var(--mg-color-quinary); }

.mg-color-warning {
  color: var(--mg-color-orange); }

.mg-color-danger {
  color: var(--mg-color-red); }

.mg-color-success {
  color: var(--mg-color-green); }

.mg-color-light {
  color: var(--mg-color-light); }

.mg-color-dark {
  color: var(--mg-color-dark); }

.mg-bg-color-initial {
  background-color: var(--mg-color-initial); }

.mg-bg-color-primary {
  background-color: var(--mg-color-primary); }

.mg-bg-color-secondary {
  background-color: var(--mg-color-secondary); }

.mg-bg-color-tertiary {
  background-color: var(--mg-color-tertiary); }

.mg-bg-color-quaternary {
  background-color: var(--mg-color-quaternary); }

.mg-bg-color-quinary {
  background-color: var(--mg-color-quinary); }

.mg-bg-color-warning {
  background-color: var(--mg-color-orange); }

.mg-bg-color-danger {
  background-color: var(--mg-color-red); }

.mg-bg-color-success {
  background-color: var(--mg-color-green); }

.mg-bg-color-light {
  background-color: var(--mg-color-light); }

.mg-bg-color-dark {
  background-color: var(--mg-color-dark); }

*,
*:after,
*:before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  color: var(--mg-color-secondary);
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1.6; }

blockquote {
  border-left: 0.3rem solid var(--mg-color-quaternary);
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem; }
  blockquote *:last-child {
    margin-bottom: 0; }

.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: var(--mg-color-primary);
  border: 0.2rem solid var(--mg-color-primary);
  border-radius: var(--mg-control-radius);
  color: var(--mg-color-light);
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: 0.1rem;
  line-height: 3.8rem;
  padding: 0 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 1;
  box-shadow: var(--mg-control-shadow); }
  .button:focus, .button:hover,
  button:focus,
  button:hover,
  input[type="button"]:focus,
  input[type="button"]:hover,
  input[type="reset"]:focus,
  input[type="reset"]:hover,
  input[type="submit"]:focus,
  input[type="submit"]:hover {
    transition: opacity 0.5s;
    outline: 0;
    opacity: 0.9;
    color: var(--mg-color-light); }
  .button[disabled],
  button[disabled],
  input[type="button"][disabled],
  input[type="reset"][disabled],
  input[type="submit"][disabled] {
    cursor: default;
    opacity: 0.5; }
    .button[disabled]:focus, .button[disabled]:hover,
    button[disabled]:focus,
    button[disabled]:hover,
    input[type="button"][disabled]:focus,
    input[type="button"][disabled]:hover,
    input[type="reset"][disabled]:focus,
    input[type="reset"][disabled]:hover,
    input[type="submit"][disabled]:focus,
    input[type="submit"][disabled]:hover {
      background-color: var(--mg-color-secondary);
      border-color: var(--mg-color-primary); }
  .button.button--outline,
  button.button--outline,
  input[type="button"].button--outline,
  input[type="reset"].button--outline,
  input[type="submit"].button--outline {
    background-color: transparent;
    color: var(--mg-color-primary); }
    .button.button--outline:focus, .button.button--outline:hover,
    button.button--outline:focus,
    button.button--outline:hover,
    input[type="button"].button--outline:focus,
    input[type="button"].button--outline:hover,
    input[type="reset"].button--outline:focus,
    input[type="reset"].button--outline:hover,
    input[type="submit"].button--outline:focus,
    input[type="submit"].button--outline:hover {
      transition: opacity 0.5s;
      opacity: 0.9; }
    .button.button--outline[disabled]:focus, .button.button--outline[disabled]:hover,
    button.button--outline[disabled]:focus,
    button.button--outline[disabled]:hover,
    input[type="button"].button--outline[disabled]:focus,
    input[type="button"].button--outline[disabled]:hover,
    input[type="reset"].button--outline[disabled]:focus,
    input[type="reset"].button--outline[disabled]:hover,
    input[type="submit"].button--outline[disabled]:focus,
    input[type="submit"].button--outline[disabled]:hover {
      border-color: inherit; }
  .button.button--link,
  button.button--link,
  input[type="button"].button--link,
  input[type="reset"].button--link,
  input[type="submit"].button--link {
    box-shadow: none;
    background-color: transparent;
    color: var(--mg-color-primary);
    border: none; }
    .button.button--link:focus, .button.button--link:hover,
    button.button--link:focus,
    button.button--link:hover,
    input[type="button"].button--link:focus,
    input[type="button"].button--link:hover,
    input[type="reset"].button--link:focus,
    input[type="reset"].button--link:hover,
    input[type="submit"].button--link:focus,
    input[type="submit"].button--link:hover {
      transition: opacity 0.5s;
      opacity: 0.9;
      color: var(--mg-color-secondary); }
    .button.button--link[disabled]:focus, .button.button--link[disabled]:hover,
    button.button--link[disabled]:focus,
    button.button--link[disabled]:hover,
    input[type="button"].button--link[disabled]:focus,
    input[type="button"].button--link[disabled]:hover,
    input[type="reset"].button--link[disabled]:focus,
    input[type="reset"].button--link[disabled]:hover,
    input[type="submit"].button--link[disabled]:focus,
    input[type="submit"].button--link[disabled]:hover {
      border-color: inherit; }
  .button.button--clear,
  button.button--clear,
  input[type="button"].button--clear,
  input[type="reset"].button--clear,
  input[type="submit"].button--clear {
    background-color: transparent;
    color: var(--mg-color-primary);
    border: none; }
    .button.button--clear:focus, .button.button--clear:hover,
    button.button--clear:focus,
    button.button--clear:hover,
    input[type="button"].button--clear:focus,
    input[type="button"].button--clear:hover,
    input[type="reset"].button--clear:focus,
    input[type="reset"].button--clear:hover,
    input[type="submit"].button--clear:focus,
    input[type="submit"].button--clear:hover {
      transition: opacity 0.5s;
      opacity: 0.9; }
    .button.button--clear[disabled]:focus, .button.button--clear[disabled]:hover,
    button.button--clear[disabled]:focus,
    button.button--clear[disabled]:hover,
    input[type="button"].button--clear[disabled]:focus,
    input[type="button"].button--clear[disabled]:hover,
    input[type="reset"].button--clear[disabled]:focus,
    input[type="reset"].button--clear[disabled]:hover,
    input[type="submit"].button--clear[disabled]:focus,
    input[type="submit"].button--clear[disabled]:hover {
      border-color: inherit; }
  .button.button--small,
  button.button--small,
  input[type="button"].button--small,
  input[type="reset"].button--small,
  input[type="submit"].button--small {
    font-size: 0.8rem;
    height: 2.8rem;
    line-height: 2.6rem;
    padding: 0 1.5rem; }
  .button.button--large,
  button.button--large,
  input[type="button"].button--large,
  input[type="reset"].button--large,
  input[type="submit"].button--large {
    font-size: 1.4rem;
    height: 4.5rem;
    line-height: 4.5rem;
    padding: 0 2rem; }
  .button.button--transparent,
  button.button--transparent,
  input[type="button"].button--transparent,
  input[type="reset"].button--transparent,
  input[type="submit"].button--transparent {
    background-color: transparent;
    border-color: transparent; }

code {
  background: var(--mg-color-quinary);
  border-radius: 0.4rem;
  font-size: 86%;
  margin: 0 0.2rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap; }

pre {
  background: var(--mg-color-quinary);
  border-left: 0.3rem solid var(--mg-color-primary);
  overflow-y: hidden; }
  pre > code {
    border-radius: 0;
    display: block;
    padding: 1rem 1.5rem;
    white-space: pre; }

hr {
  border: 0;
  border-top: 0.1rem solid var(--mg-color-tertiary);
  margin: 3.0rem 0; }

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: 0.1rem solid var(--mg-color-quaternary);
  border-radius: var(--mg-input-radius);
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: 0.6rem 0.7rem;
  width: 100%;
  color: var(--mg-color-secondary); }
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  input[type="text"]:focus,
  input[type="url"]:focus,
  textarea:focus,
  select:focus {
    border-color: var(--mg-color-primary);
    outline: 0; }

select {
  background-color: var(--mg-color-initial); }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

textarea {
  min-height: 6.5rem; }

label,
legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.5rem; }

fieldset {
  border-width: 0;
  padding: 0; }

input[type="checkbox"],
input[type="radio"] {
  display: inline; }

a {
  color: var(--mg-color-primary);
  text-decoration: none; }
  a:focus, a:hover {
    color: var(--mg-color-secondary); }

dl,
ol,
ul {
  list-style: none;
  margin-top: 0;
  padding-left: 0; }
  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    font-size: 90%;
    margin: 1.5rem 0 1.5rem 3.0rem; }

ol {
  list-style: decimal inside; }

ul {
  list-style: circle inside; }

.button,
button,
dd,
dt,
li {
  margin-bottom: 1.0rem; }

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem; }

blockquote,
dl,
figure,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 2.5rem; }

table {
  border-spacing: 0;
  width: 100%; }

td,
th {
  border-bottom: 0.1rem solid var(--mg-color-quinary);
  padding: 1.2rem 1.5rem;
  text-align: left; }
  td:first-child,
  th:first-child {
    padding-left: 0; }
  td:last-child,
  th:last-child {
    padding-right: 0; }

b,
strong {
  font-weight: bold; }

p {
  margin-top: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  letter-spacing: -.1rem;
  margin-bottom: 2.0rem;
  margin-top: 0; }

h1 {
  font-size: 4.6rem;
  line-height: 1.2; }

h2 {
  font-size: 3.6rem;
  line-height: 1.25; }

h3 {
  font-size: 2.8rem;
  line-height: 1.3; }

h4 {
  font-size: 2.2rem;
  letter-spacing: -.08rem;
  line-height: 1.35; }

h5 {
  font-size: 1.8rem;
  letter-spacing: -.05rem;
  line-height: 1.5; }

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4; }

img {
  max-width: 100%; }

.clearfix:after {
  clear: both;
  content: ' ';
  display: table; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.mg-x1, .mg-s1, .mg-m1, .mg-l1, .mg-xl1, .mg-x2, .mg-s2, .mg-m2, .mg-l2, .mg-xl2, .mg-x3, .mg-s3, .mg-m3, .mg-l3, .mg-xl3, .mg-x4, .mg-s4, .mg-m4, .mg-l4, .mg-xl4, .mg-x5, .mg-s5, .mg-m5, .mg-l5, .mg-xl5, .mg-x6, .mg-s6, .mg-m6, .mg-l6, .mg-xl6, .mg-x7, .mg-s7, .mg-m7, .mg-l7, .mg-xl7, .mg-x8, .mg-s8, .mg-m8, .mg-l8, .mg-xl8, .mg-x9, .mg-s9, .mg-m9, .mg-l9, .mg-xl9, .mg-x10, .mg-s10, .mg-m10, .mg-l10, .mg-xl10, .mg-x11, .mg-s11, .mg-m11, .mg-l11, .mg-xl11, .mg-x12, .mg-s12, .mg-m12, .mg-l12, .mg-xl12 {
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 1px;
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.mg-x1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.mg-x2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.mg-x3 {
  flex-basis: 25%;
  max-width: 25%; }

.mg-x4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.mg-x5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.mg-x6 {
  flex-basis: 50%;
  max-width: 50%; }

.mg-x7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.mg-x8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.mg-x9 {
  flex-basis: 75%;
  max-width: 75%; }

.mg-x10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.mg-x11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.mg-x12 {
  flex-basis: 100%;
  max-width: 100%; }

.mg-x--offset0 {
  margin-left: 0%; }

.mg-x--offset1 {
  margin-left: 8.33333%; }

.mg-x--offset2 {
  margin-left: 16.66667%; }

.mg-x--offset3 {
  margin-left: 25%; }

.mg-x--offset4 {
  margin-left: 33.33333%; }

.mg-x--offset5 {
  margin-left: 41.66667%; }

.mg-x--offset6 {
  margin-left: 50%; }

.mg-x--offset7 {
  margin-left: 58.33333%; }

.mg-x--offset8 {
  margin-left: 66.66667%; }

.mg-x--offset9 {
  margin-left: 75%; }

.mg-x--offset10 {
  margin-left: 83.33333%; }

.mg-x--offset11 {
  margin-left: 91.66667%; }

.mg-x--offset12 {
  margin-left: 100%; }

.mg-x--order0 {
  order: 0; }

.mg-x--order1 {
  order: 1; }

.mg-x--order2 {
  order: 2; }

.mg-x--order3 {
  order: 3; }

.mg-x--order4 {
  order: 4; }

.mg-x--order5 {
  order: 5; }

.mg-x--order6 {
  order: 6; }

.mg-x--order7 {
  order: 7; }

.mg-x--order8 {
  order: 8; }

.mg-x--order9 {
  order: 9; }

.mg-x--order10 {
  order: 10; }

.mg-x--order11 {
  order: 11; }

.mg-x--order12 {
  order: 12; }

.mg-row.mg-x--start {
  justify-content: flex-start; }

.mg-row.mg-x--center {
  justify-content: center; }

.mg-row.mg-x--end {
  justify-content: flex-end; }

.mg-row.mg-x--around {
  justify-content: space-around; }

.mg-row.mg-x--between {
  justify-content: space-between; }

.mg-row.mg-x--evenly {
  justify-content: space-evenly; }

.mg-row.mg-x--top {
  align-items: flex-start; }

.mg-row.mg-x--middle {
  align-items: center; }

.mg-row.mg-x--bottom {
  align-items: flex-end; }

.mg-row.mg-x--stretch {
  align-items: stretch; }

.mg-row.mg-x--baseline {
  align-items: baseline; }

.mg-row.mg-x--reverse {
  flex-direction: row-reverse; }

.mg-row.mg-x--grow {
  flex: 1 1 auto; }

.mg-row.mg-x--ungrow {
  flex: 0; }

.mg-col.mg-x--start {
  align-items: flex-start; }

.mg-col.mg-x--center {
  align-items: center; }

.mg-col.mg-x--end {
  align-items: flex-end; }

.mg-col.mg-x--around {
  justify-content: space-around; }

.mg-col.mg-x--between {
  justify-content: space-between; }

.mg-col.mg-x--evenly {
  justify-content: space-evenly; }

.mg-col.mg-x--top {
  justify-content: flex-start; }

.mg-col.mg-x--middle {
  justify-content: center; }

.mg-col.mg-x--bottom {
  justify-content: flex-end; }

.mg-col.mg-x--stretch {
  justify-content: stretch; }

.mg-col.mg-x--baseline {
  justify-content: baseline; }

.mg-col.mg-x--reverse {
  flex-direction: column-reverse; }

@media (min-width: 481px) {
  .mg-s1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .mg-s2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .mg-s3 {
    flex-basis: 25%;
    max-width: 25%; }
  .mg-s4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .mg-s5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .mg-s6 {
    flex-basis: 50%;
    max-width: 50%; }
  .mg-s7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .mg-s8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .mg-s9 {
    flex-basis: 75%;
    max-width: 75%; }
  .mg-s10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .mg-s11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .mg-s12 {
    flex-basis: 100%;
    max-width: 100%; }
  .mg-s--offset0 {
    margin-left: 0%; }
  .mg-s--offset1 {
    margin-left: 8.33333%; }
  .mg-s--offset2 {
    margin-left: 16.66667%; }
  .mg-s--offset3 {
    margin-left: 25%; }
  .mg-s--offset4 {
    margin-left: 33.33333%; }
  .mg-s--offset5 {
    margin-left: 41.66667%; }
  .mg-s--offset6 {
    margin-left: 50%; }
  .mg-s--offset7 {
    margin-left: 58.33333%; }
  .mg-s--offset8 {
    margin-left: 66.66667%; }
  .mg-s--offset9 {
    margin-left: 75%; }
  .mg-s--offset10 {
    margin-left: 83.33333%; }
  .mg-s--offset11 {
    margin-left: 91.66667%; }
  .mg-s--offset12 {
    margin-left: 100%; }
  .mg-s--order0 {
    order: 0; }
  .mg-s--order1 {
    order: 1; }
  .mg-s--order2 {
    order: 2; }
  .mg-s--order3 {
    order: 3; }
  .mg-s--order4 {
    order: 4; }
  .mg-s--order5 {
    order: 5; }
  .mg-s--order6 {
    order: 6; }
  .mg-s--order7 {
    order: 7; }
  .mg-s--order8 {
    order: 8; }
  .mg-s--order9 {
    order: 9; }
  .mg-s--order10 {
    order: 10; }
  .mg-s--order11 {
    order: 11; }
  .mg-s--order12 {
    order: 12; }
  .mg-row.mg-s--start {
    justify-content: flex-start; }
  .mg-row.mg-s--center {
    justify-content: center; }
  .mg-row.mg-s--end {
    justify-content: flex-end; }
  .mg-row.mg-s--around {
    justify-content: space-around; }
  .mg-row.mg-s--between {
    justify-content: space-between; }
  .mg-row.mg-s--evenly {
    justify-content: space-evenly; }
  .mg-row.mg-s--top {
    align-items: flex-start; }
  .mg-row.mg-s--middle {
    align-items: center; }
  .mg-row.mg-s--bottom {
    align-items: flex-end; }
  .mg-row.mg-s--stretch {
    align-items: stretch; }
  .mg-row.mg-s--baseline {
    align-items: baseline; }
  .mg-row.mg-s--reverse {
    flex-direction: row-reverse; }
  .mg-row.mg-s--grow {
    flex: 1 1 auto; }
  .mg-row.mg-s--ungrow {
    flex: 0; }
  .mg-col.mg-s--start {
    align-items: flex-start; }
  .mg-col.mg-s--center {
    align-items: center; }
  .mg-col.mg-s--end {
    align-items: flex-end; }
  .mg-col.mg-s--around {
    justify-content: space-around; }
  .mg-col.mg-s--between {
    justify-content: space-between; }
  .mg-col.mg-s--evenly {
    justify-content: space-evenly; }
  .mg-col.mg-s--top {
    justify-content: flex-start; }
  .mg-col.mg-s--middle {
    justify-content: center; }
  .mg-col.mg-s--bottom {
    justify-content: flex-end; }
  .mg-col.mg-s--stretch {
    justify-content: stretch; }
  .mg-col.mg-s--baseline {
    justify-content: baseline; }
  .mg-col.mg-s--reverse {
    flex-direction: column-reverse; } }

@media (min-width: 769px) {
  .mg-m1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .mg-m2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .mg-m3 {
    flex-basis: 25%;
    max-width: 25%; }
  .mg-m4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .mg-m5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .mg-m6 {
    flex-basis: 50%;
    max-width: 50%; }
  .mg-m7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .mg-m8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .mg-m9 {
    flex-basis: 75%;
    max-width: 75%; }
  .mg-m10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .mg-m11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .mg-m12 {
    flex-basis: 100%;
    max-width: 100%; }
  .mg-m--offset0 {
    margin-left: 0%; }
  .mg-m--offset1 {
    margin-left: 8.33333%; }
  .mg-m--offset2 {
    margin-left: 16.66667%; }
  .mg-m--offset3 {
    margin-left: 25%; }
  .mg-m--offset4 {
    margin-left: 33.33333%; }
  .mg-m--offset5 {
    margin-left: 41.66667%; }
  .mg-m--offset6 {
    margin-left: 50%; }
  .mg-m--offset7 {
    margin-left: 58.33333%; }
  .mg-m--offset8 {
    margin-left: 66.66667%; }
  .mg-m--offset9 {
    margin-left: 75%; }
  .mg-m--offset10 {
    margin-left: 83.33333%; }
  .mg-m--offset11 {
    margin-left: 91.66667%; }
  .mg-m--offset12 {
    margin-left: 100%; }
  .mg-m--order0 {
    order: 0; }
  .mg-m--order1 {
    order: 1; }
  .mg-m--order2 {
    order: 2; }
  .mg-m--order3 {
    order: 3; }
  .mg-m--order4 {
    order: 4; }
  .mg-m--order5 {
    order: 5; }
  .mg-m--order6 {
    order: 6; }
  .mg-m--order7 {
    order: 7; }
  .mg-m--order8 {
    order: 8; }
  .mg-m--order9 {
    order: 9; }
  .mg-m--order10 {
    order: 10; }
  .mg-m--order11 {
    order: 11; }
  .mg-m--order12 {
    order: 12; }
  .mg-row.mg-m--start {
    justify-content: flex-start; }
  .mg-row.mg-m--center {
    justify-content: center; }
  .mg-row.mg-m--end {
    justify-content: flex-end; }
  .mg-row.mg-m--around {
    justify-content: space-around; }
  .mg-row.mg-m--between {
    justify-content: space-between; }
  .mg-row.mg-m--evenly {
    justify-content: space-evenly; }
  .mg-row.mg-m--top {
    align-items: flex-start; }
  .mg-row.mg-m--middle {
    align-items: center; }
  .mg-row.mg-m--bottom {
    align-items: flex-end; }
  .mg-row.mg-m--stretch {
    align-items: stretch; }
  .mg-row.mg-m--baseline {
    align-items: baseline; }
  .mg-row.mg-m--reverse {
    flex-direction: row-reverse; }
  .mg-row.mg-m--grow {
    flex: 1 1 auto; }
  .mg-row.mg-m--ungrow {
    flex: 0; }
  .mg-col.mg-m--start {
    align-items: flex-start; }
  .mg-col.mg-m--center {
    align-items: center; }
  .mg-col.mg-m--end {
    align-items: flex-end; }
  .mg-col.mg-m--around {
    justify-content: space-around; }
  .mg-col.mg-m--between {
    justify-content: space-between; }
  .mg-col.mg-m--evenly {
    justify-content: space-evenly; }
  .mg-col.mg-m--top {
    justify-content: flex-start; }
  .mg-col.mg-m--middle {
    justify-content: center; }
  .mg-col.mg-m--bottom {
    justify-content: flex-end; }
  .mg-col.mg-m--stretch {
    justify-content: stretch; }
  .mg-col.mg-m--baseline {
    justify-content: baseline; }
  .mg-col.mg-m--reverse {
    flex-direction: column-reverse; } }

@media (min-width: 1025px) {
  .mg-l1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .mg-l2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .mg-l3 {
    flex-basis: 25%;
    max-width: 25%; }
  .mg-l4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .mg-l5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .mg-l6 {
    flex-basis: 50%;
    max-width: 50%; }
  .mg-l7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .mg-l8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .mg-l9 {
    flex-basis: 75%;
    max-width: 75%; }
  .mg-l10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .mg-l11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .mg-l12 {
    flex-basis: 100%;
    max-width: 100%; }
  .mg-l--offset0 {
    margin-left: 0%; }
  .mg-l--offset1 {
    margin-left: 8.33333%; }
  .mg-l--offset2 {
    margin-left: 16.66667%; }
  .mg-l--offset3 {
    margin-left: 25%; }
  .mg-l--offset4 {
    margin-left: 33.33333%; }
  .mg-l--offset5 {
    margin-left: 41.66667%; }
  .mg-l--offset6 {
    margin-left: 50%; }
  .mg-l--offset7 {
    margin-left: 58.33333%; }
  .mg-l--offset8 {
    margin-left: 66.66667%; }
  .mg-l--offset9 {
    margin-left: 75%; }
  .mg-l--offset10 {
    margin-left: 83.33333%; }
  .mg-l--offset11 {
    margin-left: 91.66667%; }
  .mg-l--offset12 {
    margin-left: 100%; }
  .mg-l--order0 {
    order: 0; }
  .mg-l--order1 {
    order: 1; }
  .mg-l--order2 {
    order: 2; }
  .mg-l--order3 {
    order: 3; }
  .mg-l--order4 {
    order: 4; }
  .mg-l--order5 {
    order: 5; }
  .mg-l--order6 {
    order: 6; }
  .mg-l--order7 {
    order: 7; }
  .mg-l--order8 {
    order: 8; }
  .mg-l--order9 {
    order: 9; }
  .mg-l--order10 {
    order: 10; }
  .mg-l--order11 {
    order: 11; }
  .mg-l--order12 {
    order: 12; }
  .mg-row.mg-l--start {
    justify-content: flex-start; }
  .mg-row.mg-l--center {
    justify-content: center; }
  .mg-row.mg-l--end {
    justify-content: flex-end; }
  .mg-row.mg-l--around {
    justify-content: space-around; }
  .mg-row.mg-l--between {
    justify-content: space-between; }
  .mg-row.mg-l--evenly {
    justify-content: space-evenly; }
  .mg-row.mg-l--top {
    align-items: flex-start; }
  .mg-row.mg-l--middle {
    align-items: center; }
  .mg-row.mg-l--bottom {
    align-items: flex-end; }
  .mg-row.mg-l--stretch {
    align-items: stretch; }
  .mg-row.mg-l--baseline {
    align-items: baseline; }
  .mg-row.mg-l--reverse {
    flex-direction: row-reverse; }
  .mg-row.mg-l--grow {
    flex: 1 1 auto; }
  .mg-row.mg-l--ungrow {
    flex: 0; }
  .mg-col.mg-l--start {
    align-items: flex-start; }
  .mg-col.mg-l--center {
    align-items: center; }
  .mg-col.mg-l--end {
    align-items: flex-end; }
  .mg-col.mg-l--around {
    justify-content: space-around; }
  .mg-col.mg-l--between {
    justify-content: space-between; }
  .mg-col.mg-l--evenly {
    justify-content: space-evenly; }
  .mg-col.mg-l--top {
    justify-content: flex-start; }
  .mg-col.mg-l--middle {
    justify-content: center; }
  .mg-col.mg-l--bottom {
    justify-content: flex-end; }
  .mg-col.mg-l--stretch {
    justify-content: stretch; }
  .mg-col.mg-l--baseline {
    justify-content: baseline; }
  .mg-col.mg-l--reverse {
    flex-direction: column-reverse; } }

@media (min-width: 1441px) {
  .mg-xl1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .mg-xl2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .mg-xl3 {
    flex-basis: 25%;
    max-width: 25%; }
  .mg-xl4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .mg-xl5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .mg-xl6 {
    flex-basis: 50%;
    max-width: 50%; }
  .mg-xl7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .mg-xl8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .mg-xl9 {
    flex-basis: 75%;
    max-width: 75%; }
  .mg-xl10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .mg-xl11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .mg-xl12 {
    flex-basis: 100%;
    max-width: 100%; }
  .mg-xl--offset0 {
    margin-left: 0%; }
  .mg-xl--offset1 {
    margin-left: 8.33333%; }
  .mg-xl--offset2 {
    margin-left: 16.66667%; }
  .mg-xl--offset3 {
    margin-left: 25%; }
  .mg-xl--offset4 {
    margin-left: 33.33333%; }
  .mg-xl--offset5 {
    margin-left: 41.66667%; }
  .mg-xl--offset6 {
    margin-left: 50%; }
  .mg-xl--offset7 {
    margin-left: 58.33333%; }
  .mg-xl--offset8 {
    margin-left: 66.66667%; }
  .mg-xl--offset9 {
    margin-left: 75%; }
  .mg-xl--offset10 {
    margin-left: 83.33333%; }
  .mg-xl--offset11 {
    margin-left: 91.66667%; }
  .mg-xl--offset12 {
    margin-left: 100%; }
  .mg-xl--order0 {
    order: 0; }
  .mg-xl--order1 {
    order: 1; }
  .mg-xl--order2 {
    order: 2; }
  .mg-xl--order3 {
    order: 3; }
  .mg-xl--order4 {
    order: 4; }
  .mg-xl--order5 {
    order: 5; }
  .mg-xl--order6 {
    order: 6; }
  .mg-xl--order7 {
    order: 7; }
  .mg-xl--order8 {
    order: 8; }
  .mg-xl--order9 {
    order: 9; }
  .mg-xl--order10 {
    order: 10; }
  .mg-xl--order11 {
    order: 11; }
  .mg-xl--order12 {
    order: 12; }
  .mg-row.mg-xl--start {
    justify-content: flex-start; }
  .mg-row.mg-xl--center {
    justify-content: center; }
  .mg-row.mg-xl--end {
    justify-content: flex-end; }
  .mg-row.mg-xl--around {
    justify-content: space-around; }
  .mg-row.mg-xl--between {
    justify-content: space-between; }
  .mg-row.mg-xl--evenly {
    justify-content: space-evenly; }
  .mg-row.mg-xl--top {
    align-items: flex-start; }
  .mg-row.mg-xl--middle {
    align-items: center; }
  .mg-row.mg-xl--bottom {
    align-items: flex-end; }
  .mg-row.mg-xl--stretch {
    align-items: stretch; }
  .mg-row.mg-xl--baseline {
    align-items: baseline; }
  .mg-row.mg-xl--reverse {
    flex-direction: row-reverse; }
  .mg-row.mg-xl--grow {
    flex: 1 1 auto; }
  .mg-row.mg-xl--ungrow {
    flex: 0; }
  .mg-col.mg-xl--start {
    align-items: flex-start; }
  .mg-col.mg-xl--center {
    align-items: center; }
  .mg-col.mg-xl--end {
    align-items: flex-end; }
  .mg-col.mg-xl--around {
    justify-content: space-around; }
  .mg-col.mg-xl--between {
    justify-content: space-between; }
  .mg-col.mg-xl--evenly {
    justify-content: space-evenly; }
  .mg-col.mg-xl--top {
    justify-content: flex-start; }
  .mg-col.mg-xl--middle {
    justify-content: center; }
  .mg-col.mg-xl--bottom {
    justify-content: flex-end; }
  .mg-col.mg-xl--stretch {
    justify-content: stretch; }
  .mg-col.mg-xl--baseline {
    justify-content: baseline; }
  .mg-col.mg-xl--reverse {
    flex-direction: column-reverse; } }

@media (max-width: 481px) {
  .mg-s--hidden {
    display: none; } }

@media (max-width: 769px) {
  .mg-m--hidden {
    display: none; } }

@media (max-width: 1025px) {
  .mg-l--hidden {
    display: none; } }

@media (max-width: 1441px) {
  .mg-xl--hidden {
    display: none; } }

.mg-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1025px; }

.mg-container-fluid {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: width auto; }

.mg-row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }

.mg-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.mg-col > * {
  flex: 0 0 auto; }

/* The container must be positioned relative: */
.mg-dropdown {
  position: relative;
  margin-bottom: 1.5rem;
  display: inline-block; }

.mg-dropdown input {
  display: none;
  /*hide original SELECT element: */ }

.mg-dropdown--button {
  width: 100%;
  margin-bottom: 0;
  padding-right: 2em; }

.mg-dropdown--button:after {
  border: 2px solid transparent;
  border-color: var(--mg-color-light);
  right: 0.8rem;
  border-right: 0;
  border-top: 0;
  content: "";
  display: block;
  height: 1rem;
  margin-top: -0.8rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 1rem;
  transition: all 0.2s; }

/* Point the arrow upwards when the select box is open (active): */
.mg-dropdown.opened > .mg-dropdown--button:after {
  transform: rotate(135deg);
  margin-top: -0.4rem; }

.mg-dropdown--button.button--clear:after {
  border-color: var(--mg-color-secondary); }

.mg-dropdown--button.button--outline:after {
  border-color: var(--mg-color-secondary); }

.mg-dropdown--menu {
  width: max-content;
  box-shadow: var(--mg-control-shadow);
  min-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: none;
  position: absolute;
  background-color: var(--mg-color-initial);
  top: 100%;
  z-index: 1;
  border-color: var(--mg-color-quaternary);
  border-radius: var(--mg-input-radius);
  overflow-x: hidden; }

/* Style items (options): */
.mg-dropdown--menu > ul {
  list-style-type: none;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 0;
  margin-bottom: 0; }

/* style the items (options), including the selected item: */
.mg-dropdown--menu > ul > li {
  padding-right: 2rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left; }

/* style the items (options), including the selected item: */
.mg-dropdown--menu > ul > li > a {
  display: block;
  color: var(--mg-color-secondary); }

.mg-dropdown--menu > ul > li:hover {
  background-color: var(--mg-color-quinary); }

.mg-dropdown--menu.mg-dropdown--menu-fit {
  min-width: 100%; }

.mg-dropdown--menu.mg-dropdown--menu-right {
  right: 0; }

.mg-dropdown--menu.mg-dropdown--menu-left {
  left: 0; }

.mg-dropdown.opened > .mg-dropdown--menu {
  display: block; }

.mg-tabs > ul {
  display: flex;
  text-align: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  list-style: none;
  cursor: pointer;
  margin: 0; }

.mg-tabs > ul > li > * {
  color: var(--mg-color-secondary);
  white-space: nowrap; }

.mg-tabs > ul > li {
  font-weight: bold;
  border-bottom: 0.3rem solid transparent;
  color: var(--mg-color-tertiary);
  opacity: 0.7;
  flex: 0 0 auto; }

.mg-tabs > ul > li.active {
  color: var(--mg-color-primary);
  border-color: var(--mg-color-primary);
  overflow-y: hidden;
  opacity: 1; }

.mg-tabs > ul > li:hover {
  color: var(--mg-color-secondary);
  border-color: var(--mg-color-tertiary);
  opacity: 1;
  transition: all 0.3s; }

.mg-tabs--item {
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding-left: 0.2em;
  padding-right: 0.2em; }

.mg-tabs--content {
  display: none;
  padding-bottom: 1em;
  border-bottom: 1px solid transparent;
  border-color: var(--mg-color-quaternary);
  clear: both; }

.mg-select {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: top;
  margin-bottom: 1.5rem; }

.mg-select > select {
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 1em;
  cursor: pointer; }

.mg-select:not(.is-multiple) {
  height: 2.5em; }

.mg-select:not(.is-multiple):not(.is-loading)::after {
  border-color: var(--mg-color-primary);
  z-index: 4;
  right: 0.6em; }

.mg-select::after {
  border: 2px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 1rem;
  margin-top: -0.8rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 1rem; }

/**/
.mg-select--selected {
  background-color: var(--mg-color-initial);
  border-radius: var(--mg-input-radius); }

/* Style the arrow inside the select element: */
.mg-select--selected:after {
  margin-right: 0.5em;
  font-family: Arial;
  position: absolute;
  content: "";
  top: 50%;
  right: 0.2em;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: var(--mg-color-secondary) transparent transparent transparent; }

/* Point the arrow upwards when the select box is open (active): */
.mg-select--selected.mg-select--arrow-active:after {
  font-family: Arial;
  border-color: transparent transparent var(--mg-color-secondary) transparent; }

/* style the items (options), including the selected item: */
.mg-select--items div,
.mg-select--selected {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: var(--mg-color-quaternary);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* Style items (options): */
.mg-select--items {
  position: absolute;
  background-color: var(--mg-color-initial);
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 10em;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: var(--mg-input-radius); }

/* Hide the items when the select box is closed: */
.mg-select--hide {
  display: none; }

.mg-select--items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1); }

.mg-alert {
  border-left: 0.3rem solid var(--mg-color-primary);
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
  background-color: var(--mg-color-quinary); }

.mg-alert.mg-alert--danger {
  border-left-color: var(--mg-color-red); }

.mg-alert.mg-alert--success {
  border-left-color: var(--mg-color-green); }

.mg-alert.mg-alert--warning {
  border-left-color: var(--mg-color-orange); }

.mg-alert *:last-child {
  margin: 0; }

/* The close button */
.mg-alert--closebtn {
  margin: 1.5em;
  color: var(--mg-color-secondary);
  float: right;
  font-size: 1.5em;
  line-height: 1em;
  cursor: pointer;
  transition: 0.3s; }

/* When moving the mouse over the close button */
.mg-alert--closebtn:hover {
  opacity: 0.8; }

/* Customize the label (the container) */
/* Hide the browser's default radio button */
.mg-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom radio button */
.mg-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--mg-color-quaternary);
  border-width: thin;
  transition: all 0.4s; }

/* On mouse-over, add a grey background color */
.mg-radio:hover input ~ .checkmark {
  background-color: var(--mg-color-secondary); }

/* When the radio button is checked, add a blue background */
.mg-radio input:checked ~ .checkmark {
  background-color: var(--mg-color-primary); }

/* Create the indicator (the dot/circle - hidden when not checked) */
.mg-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
.mg-radio input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.mg-radio .checkmark:after {
  top: 0.5em;
  left: 0.5em;
  width: 0.4em;
  height: 0.4em;
  border-radius: 50%;
  background-color: var(--mg-color-light); }

.mg-radio input:disabled ~ .checkmark {
  background-color: var(--mg-color-quinary); }

.mg-radio input:focus ~ .checkmark {
  border-color: var(--mg-color-primary);
  border-style: dotted;
  border-width: thin; }

.mg-label--inline {
  font-weight: 400; }

.mg-check,
.mg-radio {
  position: relative;
  padding-left: 1.8em;
  margin-right: 1.1em;
  margin-bottom: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.mg-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.mg-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5em;
  width: 1.5em;
  border-style: solid;
  border-color: var(--mg-color-quaternary);
  border-width: thin;
  transition: all 0.3s; }

/* On mouse-over, add a grey background color */
.mg-check:hover input ~ .checkmark {
  background-color: var(--mg-color-secondary); }

/* When the checkbox is checked, add a blue background */
.mg-check input:checked ~ .checkmark {
  background-color: var(--mg-color-primary); }

/* Create the checkmark/indicator (hidden when not checked) */
.mg-check .checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.mg-check input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.mg-check .checkmark:after {
  left: 0.5em;
  top: 0.1em;
  width: 0.5em;
  height: 1em;
  border: solid var(--mg-color-light);
  border-width: 0 2px 3px 0;
  transform: rotate(45deg); }

/*disabled state*/
.mg-check input:disabled ~ .checkmark {
  background-color: var(--mg-color-quinary); }

.mg-check input:focus ~ .checkmark {
  border-color: var(--mg-color-primary);
  border-style: dotted;
  border-width: thin; }

.mg-nav > ul {
  margin-bottom: 0; }

.mg-nav > ul > li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.mg-nav > ul > li.active {
  color: var(--mg-color-initial);
  border-left: 0.3rem solid var(--mg-color-primary);
  overflow-y: hidden;
  font-weight: bold; }

.mg-nav.mg-nav--inline > ul > li.active {
  border: none;
  border-bottom: 0.3rem solid var(--mg-color-primary); }

.mg-nav > ul > li a {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: var(--mg-color-secondary); }

.mg-nav > ul > li a:hover {
  background-color: var(--mg-color-quinary); }

.mg-nav--inline > ul {
  display: inline-block;
  width: 100%; }

.mg-nav--inline > ul > li:not(.mg-right) {
  float: left; }

.mg-group {
  display: flex; }

.mg-group > * {
  flex: 0 0 1;
  padding: 0; }

.mg-group > .mg-select:not(:nth-child(1)):not(:nth-last-child(1)) > select,
.mg-group > input:not(:nth-child(1)):not(:nth-last-child(1)),
.mg-group > button:not(:nth-child(1)):not(:nth-last-child(1)),
.mg-group > select:not(:nth-child(1)):not(:nth-last-child(1)),
.mg-group > textarea:not(:nth-child(1)):not(:nth-last-child(1)),
.mg-group > .button:not(:nth-child(1)):not(:nth-last-child(1)),
.mg-group > .mg-dropdown:not(:nth-child(1)):not(:nth-last-child(1)) > button {
  border-radius: 0; }

.mg-group > .mg-select:nth-child(1) > select,
.mg-group > input:nth-child(1),
.mg-group > button:nth-child(1),
.mg-group > select:nth-child(1) .mg-group > textarea:nth-child(1),
.mg-group > .mg-dropdown:nth-child(1) > .mg-dropdown--button,
.mg-group > .button:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0; }

.mg-group > .mg-select:last-child > select,
.mg-group > select:last-child,
.mg-group > input:last-child,
.mg-group > button:last-child,
.mg-group > textarea:last-child,
.mg-group > .mg-dropdown:last-child > .mg-dropdown--button,
.mg-group > .button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0; }

.mg-group .mg-group--outline {
  box-shadow: var(--mg-control-shadow); }

.mg-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  backface-visibility: hidden;
  z-index: 1000;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.3s; }

.mg-modal > .mg-modal--content {
  background-color: var(--mg-color-initial);
  border-radius: var(--mg-control-radius);
  color: var(--mg-color-secondary);
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  z-index: 2000;
  box-shadow: var(--mg-control-shadow); }

.mg-modal > .mg-modal--content > .mg-modal--body {
  max-height: 90vh;
  overflow-y: auto; }

/* The close button */
.mg-modal--closebtn {
  color: var(--mg-color-secondary);
  float: right;
  font-size: 2.5em;
  line-height: 1em;
  cursor: pointer;
  transition: 0.3s; }

/* When moving the mouse over the close button */
.mg-modal--closebtn:hover {
  opacity: 0.8; }

.mg-modal.mg-right > .mg-modal--content {
  position: fixed;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.mg-modal.mg-left > .mg-modal--content {
  position: fixed;
  left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.mg-modal.mg-bottom > .mg-modal--content {
  bottom: 0; }

.mg-modal.mg-top > .mg-modal--content {
  top: 0; }

.mg-toggle {
  position: relative;
  padding-left: 3.8em;
  margin-right: 1.1em;
  margin-bottom: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.mg-toggle input {
  opacity: 0;
  display: none;
  position: absolute;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.mg-toggle .checkmark {
  border-radius: var(--mg-control-radius);
  position: absolute;
  top: 0;
  left: 0;
  height: 1.7em;
  width: 3em;
  border-style: solid;
  border-color: var(--mg-color-quaternary);
  border-width: thin;
  transition: all 0.3s; }

/* On mouse-over, add a grey background color */
.mg-toggle input ~ .checkmark {
  background-color: var(--mg-color-quaternary); }

/* When the checkbox is checked, add a blue background */
.mg-toggle input:checked ~ .checkmark {
  background-color: var(--mg-color-primary); }

/* Create the checkmark/indicator (hidden when not checked) */
.mg-toggle .checkmark:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 1.5em;
  width: 1.4em;
  left: 0;
  background-color: var(--mg-color-light);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: var(--mg-control-shadow); }

/* Show the checkmark when checked */
.mg-toggle input:checked ~ .checkmark:after {
  left: 1.5em; }

/*disabled state*/
.mg-toggle input:disabled ~ .checkmark {
  background-color: var(--mg-color-quinary); }

.mg-toggle input:focus ~ .checkmark {
  border-color: var(--mg-color-primary);
  border-style: dotted;
  border-width: thin; }

.mg-loader--loading {
  opacity: 0.4; }

.mg-loader:not(.mg-loader--loaded) ~ .mg-loader--result,
.mg-loader:not(.mg-loader--loading) ~ .mg-loader--status {
  display: none; }

.mg-loader--loading
~ .mg-loader--result
.mg-loader--loaded
~ .mg-loader--status {
  display: none; }

.mg-timeline > ul {
  position: relative;
  padding-left: 4.5rem;
  list-style: none; }

.mg-timeline > ul::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 1.5rem;
  width: 1rem;
  height: 100%; }

.mg-timeline > ul li {
  position: relative;
  counter-increment: list;
  margin-bottom: 0; }

.mg-timeline > ul li:not(:last-child) {
  padding-bottom: 1rem; }

.mg-timeline > ul li::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -3rem;
  height: 100%;
  width: 10px;
  border-left: 0.3rem solid var(--mg-color-quaternary); }

.mg-timeline > ul li:last-child::before {
  border-color: none; }

.mg-timeline > ul li::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -3.6rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.3rem solid var(--mg-color-quaternary);
  border-radius: 50%;
  background-color: var(--mg-color-initial); }

.mg-timeline > ul li.active::before {
  border-left: 0.3rem solid var(--mg-color-primary); }

.mg-timeline > ul li:last-of-type::before {
  border-left: 0.3rem solid transparent !important; }

.mg-timeline > ul li.active::after {
  font-size: 1rem;
  text-align: center;
  border: 0.3rem solid var(--mg-color-primary);
  background-color: var(--mg-color-primary); }

.mg-timeline > ul li.current::after {
  border: 0.3rem solid var(--mg-color-primary);
  width: 1.5rem;
  height: 1.5rem;
  left: -3.6rem;
  border-radius: 50%; }

.mg-timeline-inline > ul {
  list-style: none;
  display: flex;
  flex-direction: row; }

.mg-timeline-inline > ul li {
  border-top: 0.3rem solid var(--mg-color-quaternary);
  flex: 1 1 0px; }

.mg-timeline-inline > ul li.active {
  border-color: var(--mg-color-primary); }

.mg-timeline-inline > ul li.active::before {
  font-size: 1rem;
  text-align: center;
  border-color: var(--mg-color-primary);
  background-color: var(--mg-color-primary); }

.mg-timeline-inline > ul li.current::before {
  border: 0.3rem solid var(--mg-color-primary);
  border-radius: 50%; }

.mg-timeline-inline > ul li::before {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  position: relative;
  top: -1.2rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.3rem solid var(--mg-color-quaternary);
  border-radius: 50%;
  background-color: var(--mg-color-initial); }

.mg-timeline-inline > ul li:last-of-type {
  border-top: 0.3rem solid transparent;
  flex: 0 1 auto; }

/**
 * Utilities
 */
.mg-show {
  opacity: 1;
  visibility: visible; }

.mg-right {
  float: right; }

.mg-left {
  float: left; }

.mg-clearfix {
  *zoom: 1; }

.mg-clearfix:after,
.mg-clearfix:before {
  content: "";
  display: table; }

.mg-clearfix:after {
  clear: both; }

.mg-text-left {
  text-align: left; }

.mg-text-right {
  text-align: right; }

.mg-text-center {
  text-align: center; }

.mg-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%; }

.mg-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%; }

.mg-margin-auto {
  margin: auto; }

.mg-center-block {
  display: block;
  text-align: center; }

.mg-right-block {
  display: block;
  text-align: right; }

.mg-left-block {
  display: block;
  text-align: left; }

.mg-valign-middle {
  vertical-align: middle; }

.mg-icon {
  height: 22px;
  vertical-align: middle;
  padding-right: 0.5rem; }

.mg-min-100vh {
  min-height: 100vh; }

.mg-100vh {
  height: 100vh; }

.mg-icon-close::after {
  content: "\D7"; }

.mg-label--light {
  font-weight: 400; }
